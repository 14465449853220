<template>
    <div class="container flex flex-col">
        <Clients
            :show-add-client="false"
            :page-size="10"
            @updated-client-list="updateClients"
        />
    </div>
</template>

<script>
import Clients from '@/components/clients/Clients'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'

export default {
    name: 'ClientList',
    mixins: [BackendHelpers, HttpMixin],
    components: { Clients },
    data() {
        return {
            loading: false,
            clientList: [],
            showList: true,
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', null)
    },
    methods: {
        async updateClients(clientList) {
            this.loading = false
            this.clientList = clientList
        },
    },
    computed: {
        isNewUser() {
            // return this.$auth.user != null && this.$auth.user.isNewUser
            return true
        },
    },
    watch: {
        clientList() {
            this.showList = this.clientList.length > 0
        }
    }
}
</script>
